<v-container fluid class="next-courses home-next-courses">
	<v-tabs v-model="activeTab">
		<v-tab value="available">Freie Plätze</v-tab>
		<v-tab value="next">Nächste Kurse</v-tab>
	</v-tabs>
	<v-tabs-window v-model="activeTab">
		<v-tabs-window-item value="available">
			<v-container fluid class="pb-0 pl-1">
				<h2 class="mb-0">Kurse mit freien Plätzen</h2>
			</v-container>
			<available-courses :data-source-selector="availableDataSourceSelector" />
			<v-container fluid>
				<v-row justify="center">
					<v-col cols="auto">
						<v-btn color="accent" @click="showMoreAvailable">Weitere Kurse mit freien Plätzen</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-tabs-window-item>
		<v-tabs-window-item value="next">
			<v-container fluid class="pb-0 pl-1">
				<h2 class="mb-0">Nächste Kurse</h2>
			</v-container>
			<next-courses :data-source-selector="nextDataSourceSelector" :showAllUrl="nextShowAllUrl" />
		</v-tabs-window-item>
	</v-tabs-window>
</v-container>
