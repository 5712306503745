import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./homepage-courses.html";
import { AvailableCourses } from "~/vue/available-courses";
import { NextCourses } from "~/vue/next-courses";

@Component({
	render,
	components: {
		NextCourses,
		AvailableCourses,
	},
})
export class HomepageCourses extends Vue {
	activeTab: string = "available";

	@Prop() nextDataSourceSelector: string;
	@Prop() nextShowAllUrl: string;
	@Prop() availableDataSourceSelector: string;
	@Prop() availableShowMoreUrl: string;

	showMoreAvailable() {
		location.href = this.availableShowMoreUrl;
	}
}
