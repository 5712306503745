import "../Styles/index.scss";
import "reflect-metadata";

import { defineCustomElement as defineCustomElementCore, type App, type Component } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { Vuetify3Dialog } from "vuetify3-dialog";
import { mdiArrowRight, mdiArrowLeft, mdiMagnify, mdiCalendar, mdiHeart, mdiHeartOutline, mdiDelete, mdiDeleteSweep, mdiViewGrid, mdiViewList, mdiViewAgenda, mdiClose, mdiAlert, mdiSend, mdiChevronDown } from "@mdi/js";
import { initializeMaps } from "@wagich/bluc-map";

import { Page } from "./page";
import { vuetify } from "./vuetify";
import { idSafe } from "~/utilities";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp(app) {
			app.use(vuetify);
			app.use(Vuetify3Dialog);
			app.use(pinia as any);

			app.config.globalProperties.$formatters = {
				idSafe,
			};

			app.config.globalProperties.$icons = {
				mdiArrowRight, mdiArrowLeft, mdiMagnify, mdiCalendar, mdiHeart, mdiHeartOutline, mdiDelete, mdiDeleteSweep, mdiViewGrid, mdiViewList, mdiViewAgenda, mdiClose, mdiAlert, mdiSend, mdiChevronDown,
			};
		},
	})
}

import { NewsletterSignup } from "./vue/newsletter-signup";
customElements.define("newsletter-signup", defineAndConfigureCustomElement(NewsletterSignup));

import { CourseNavigation } from "./vue/course-navigation";
customElements.define("course-navigation", defineAndConfigureCustomElement(CourseNavigation));
import { SearchBox } from "./vue/search-box";
customElements.define("search-box", defineAndConfigureCustomElement(SearchBox));
import { CourseList } from "./filter/course-list";
customElements.define("course-list", defineAndConfigureCustomElement(CourseList));

import { FavoritesTool } from "./favorites/favorites-tool";
customElements.define("favorites-tool", defineAndConfigureCustomElement(FavoritesTool));
import { FavoriteToggle } from "./favorites/favorite-toggle";
customElements.define("favorite-toggle", defineAndConfigureCustomElement(FavoriteToggle));

import { NewsletterOverlay } from "./vue/newsletter-overlay";
customElements.define("newsletter-overlay", defineAndConfigureCustomElement(NewsletterOverlay));

import { NextCourses } from "./vue/next-courses";
customElements.define("next-courses", defineAndConfigureCustomElement(NextCourses));
import { AvailableCourses } from "./vue/available-courses";
customElements.define("available-courses", defineAndConfigureCustomElement(AvailableCourses));
import { HomepageCourses } from "./vue/homepage-courses";
customElements.define("homepage-courses", defineAndConfigureCustomElement(HomepageCourses));

import { ContactForm } from "./vue/contact-form";
customElements.define("contact-form", defineAndConfigureCustomElement(ContactForm));
import { ContactFormButton } from "./vue/contact-form-button";
customElements.define("contact-form-button", defineAndConfigureCustomElement(ContactFormButton));

import { ModalTrigger } from "./vue/modal-trigger";
customElements.define("modal-trigger", defineAndConfigureCustomElement(ModalTrigger));
import { BrochureOrder } from "./vue/brochure-order";
customElements.define("brochure-order", defineAndConfigureCustomElement(BrochureOrder));
import { DownloadOrder } from "./vue/download-order";
customElements.define("download-order", defineAndConfigureCustomElement(DownloadOrder));

if (document.querySelector("oda-registration-button") != null) {
	import("./vue/oda-registration-button")
		.then(m => customElements.define("oda-registration-button", defineAndConfigureCustomElement(m.OdaRegistrationButton)));
}
if (document.querySelector("custom-form") != null) {
	import("./custom-form/custom-form")
		.then(m => customElements.define("custom-form", defineAndConfigureCustomElement(m.CustomForm)));
}

new Page();

initializeMaps();
