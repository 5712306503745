import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import groupBy from "lodash/groupBy";
import { format, fromUnixTime } from "date-fns";
import { de as deLocale } from "date-fns/locale";

import { render } from "./next-courses.html";
import { type SearchResultItem } from "../models/search-result-item";
import { idSafe } from "../utilities";
import { CourseResultAgendaItem } from '../filter/course-result-agenda-item';
import { CourseFilterStore, DisplayMode } from '../filter/course-filter-storemodule';

@Component({
	render,
	components: {
		CourseResultAgendaItem
	},
})
export class NextCourses extends Vue {
	private filterStore = new CourseFilterStore();

	courses: SearchResultItem[] = [];

	@Prop() dataSourceSelector: string;
	@Prop() showAllUrl: string;

	get coursesGroupedByMonth() {
		return groupBy(
			this.courses.map((r, i) => { return { index: i, value: r }; }),
			x => x.value.beginTimestamp ? format(fromUnixTime(x.value.beginTimestamp), "MMMM yyyy", { locale: deLocale }) : "Beginn nach Stundenplan"
		);
	}

	mounted() {
		let sourceElement = document.querySelector(this.dataSourceSelector);
		if (sourceElement != null && sourceElement.textContent != null) {
			this.courses = JSON.parse(sourceElement.textContent);
		}
	}

	showAll() {
		this.filterStore.setDisplayMode(DisplayMode.Agenda);
		location.href = this.showAllUrl;
	}
}
